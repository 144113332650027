import { createSharedComposable } from '@vueuse/core';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const _useDashboard = () => {
    const route = useRoute();
    const router = useRouter();
    const isHelpSlideoverOpen = ref(false);

    defineShortcuts({
        'g-h': () => router.push({ name: 'index' }),
        'g-b': () => router.push({ name: 'brands' }),
        'g-m': () => router.push({ name: 'models' }),
        'g-v': () => router.push({ name: 'versions' }),
        'g-d': () => router.push({ name: 'bus-dossiers' }),
        'g-f': () => router.push({ name: 'bus-factory-attributes' }),
        'g-a': () => router.push({ name: 'auctions' }),
        'g-p': () => router.push({ name: 'auction-planner' }),
        'g-s': () => router.push({ name: 'settings' }),
        '?': () => (isHelpSlideoverOpen.value = true),
    });

    watch(
        () => route.fullPath,
        () => {
            isHelpSlideoverOpen.value = false;
        }
    );

    return {
        isHelpSlideoverOpen,
    };
};

export const useDashboard = createSharedComposable(_useDashboard);
